<template>
    <el-dialog :title="op == 'add' ? '新增点位' : '修改点位'" :close-on-click-modal="false" :visible="dialogShow" @close="closeDialog">
        <el-form :model="form" ref="form" :rules="rules">
            <el-form-item prop="name" label="点位名称" label-width="200px">
                <el-input style="width: 70%" v-model="form.name" autocomplete="off" clearable placeholder="请输入点位名称"></el-input>
            </el-form-item>
            <el-form-item prop="regionStr" label="所在区域" label-width="200px">
                <el-input style="width: 70%" v-model="form.regionStr" autocomplete="off" disabled placeholder="请点击地图选址按钮"></el-input>
            </el-form-item>
            <el-form-item prop="address" label="具体地址" label-width="200px">
                <el-input style="width: 50%" v-model="form.address" autocomplete="off" disabled placeholder="请点击地图选址按钮"></el-input>
                <el-button style="width: 20%" type="primary" @click="showMap">地图选址</el-button>
            </el-form-item>
            <el-form-item label="点位照片" prop="fileList" ref="upload" label-width="200px">
                <el-upload
                    style="width: 70%"
                    :class="{ hide: hideUpload }"
                    accept="image/jpeg,image/png,image/jpg"
                    :on-remove="handleRemove"
                    :on-success="successUpload"
                    :file-list="form.fileList"
                    :limit="3"
                    :action="resources_api_host + '/file/upload'"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :before-upload="onBeforeUpload"
                >
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </div>
        <Vviewer ref="viewer"></Vviewer>
        <location-selected :locationSelectedVisible="locationSelectedVisible" @closeMapSelected="closeMapSelected" />
    </el-dialog>
</template>

<script>
import Vviewer from "@/components/Vviewer";
import LocationSelected from "@/components/LocationSelected";
import { mapGetters } from "vuex";
import moment from "moment";
import { dataPointQuery, dataPointAdd, dataPointUpdate } from "@/api/statisticalReport";

export default {
    components: { Vviewer, LocationSelected },
    props: ["visible", "op"],
    data() {
        return {
            locationSelectedVisible: false,
            hideUpload: false,
            fileList: [],
            resources_api_host: process.env.VUE_APP_RESOURCES_API_HOST,
            resources_host: process.env.VUE_APP_RESOURCES_HOST,
            moment,
            dialogShow: false,
            form: {
                name: "",
                province: "",
                city: "",
                district: "",
                address: "",
                regionStr: "",
                adcode: "",
                longitude: "",
                latitude: "",
                fileList: [],
            },
            rules: {
                name: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请输入点位名称",
                    },
                ],
                regionStr: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请打开地图选择地理位置",
                    },
                ],
                address: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请打开地图选择地理位置",
                    },
                ],
            },
        };
    },
    watch: {
        visible(data) {
            this.dialogShow = data;
            const _this = this;
            if (data && this.op != "add") {
                //查询详情
                _this.getInfo();
            }
        },
    },
    computed: {
        ...mapGetters(["userInfo"]),
    },
    methods: {
        closeMapSelected(data) {
            if (data) {
                this.form.name = data.name;
                this.form.province = data.province;
                this.form.city = data.city;
                this.form.district = data.district;
                this.form.regionStr = data.province + "/" + data.city + "/" + data.district;
                this.form.adcode = data.adcode;
                this.form.address = data.address;
                this.form.longitude = data.lng;
                this.form.latitude = data.lat;
            }
            this.locationSelectedVisible = false;
        },
        showMap() {
            this.locationSelectedVisible = true;
        },
        onBeforeUpload(file) {
            const isIMAGE = file.type == "image/jpeg" || file.type == "image/jpg" || file.type == "image/png";
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isIMAGE) {
                this.$message.error("上传文件只能是图片格式（jpg/jpeg/png）!");
                return false;
            }
            if (!isLt5M) {
                this.$message.error("上传文件大小不能超过 5MB!");
                return false;
            }
            return isIMAGE && isLt5M;
        },
        handlePictureCardPreview(file) {
            const _this = this;
            this.$refs.viewer.show([
                {
                    thumbnail: _this.resources_host + file.response.data.coverImgUrl,
                    source: _this.resources_host + file.response.data.filePath,
                },
            ]);
        },
        successUpload(res, file, fileList) {
            if (res.rescode == 200) {
                this.form.fileList = fileList;
                this.$refs.upload.clearValidate();
                this.hideUpload = this.form.fileList.length >= this.limitCount;
            }
        },
        handleRemove(file, fileList) {
            this.form.fileList = fileList;
            this.hideUpload = this.form.fileList.length >= this.limitCount;
        },
        getInfo() {
            const _this = this;
            let params = {
                pointId: this.op,
            };
            const loading = _this.$loading({
                lock: true,
                text: "加载中,请稍后...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
                customClass: "fullScreenLoading",
            });
            dataPointQuery(params)
                .then((res) => {
                    loading.close();
                    _this.form = {
                        adCode: res.data.adcode,
                        address: res.data.address,
                        city: res.data.city,
                        district: res.data.district,
                        latitude: res.data.latitude,
                        longitude: res.data.longitude,
                        name: res.data.name,
                        province: res.data.province,
                        url: res.data.url,
                        regionStr: res.data.province + "/" + res.data.city + "/" + res.data.district,
                    };
                    let url = res.data.url;
                    let urlArr = url.split(",");
                    let fileList = [];
                    urlArr.map((item) => {
                        let obj = {
                            url: _this.resources_host + item,
                            response: {
                                data: {
                                    filePath: item,
                                },
                            },
                        };
                        fileList.push(obj);
                    });
                    _this.form.fileList = fileList;
                })
                .catch(() => {
                    loading.close();
                });
        },
        submit() {
            const _this = this;
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let imgUrlArr = [];
                    let imgUrlStr = "";
                    let imgList = [...this.form.fileList];
                    imgList.map((item) => {
                        imgUrlArr.push(item.response.data.filePath);
                    });
                    imgUrlStr = imgUrlArr.join(",");
                    let params = {
                        adCode: this.form.adcode,
                        address: this.form.address,
                        city: this.form.city,
                        district: this.form.district,
                        latitude: this.form.latitude,
                        longitude: this.form.longitude,
                        name: this.form.name,
                        province: this.form.province,
                        url: imgUrlStr,
                        userCode: this.userInfo.userCode,
                    };
                    const loading = _this.$loading({
                        lock: true,
                        text: "提交中,请稍后...",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                        customClass: "fullScreenLoading",
                    });
                    if (_this.op == "add") {
                        // 新增
                        dataPointAdd(params)
                            .then(() => {
                                loading.close();
                                _this.$message({
                                    message: "操作成功",
                                    type: "success",
                                });
                                _this.closeDialog(true);
                            })
                            .catch((err) => {
                                loading.close();
                            });
                    } else {
                        // 更新
                        params.pointId = _this.op;
                        const loading = _this.$loading({
                            lock: true,
                            text: "提交中,请稍后...",
                            spinner: "el-icon-loading",
                            background: "rgba(0, 0, 0, 0.7)",
                            customClass: "fullScreenLoading",
                        });
                        dataPointUpdate(params)
                            .then(() => {
                                loading.close();
                                _this.$message({
                                    message: "操作成功",
                                    type: "success",
                                });
                                _this.closeDialog(true);
                            })
                            .catch(() => {
                                loading.close();
                            });
                    }
                }
            });
        },
        closeDialog(flag) {
            this.$emit("onDialogClose", flag);
            this.$refs.form.resetFields();
        },
    },
};
</script>
