<template>
    <div class="app-container">
        <el-row id="queryForm">
            <el-form :inline="true" ref="queryForm" :model="queryForm" @submit.native.prevent>
                <el-form-item prop="querySelectedValue">
                    <el-input placeholder="请输入内容" v-model="queryForm.querySelectedValue" class="input-with-select">
                        <el-select v-model="queryForm.querySelectedType" slot="prepend" placeholder="请选择">
                            <el-option label="点位名称" value="name"></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item prop="region">
                    <el-cascader :props="{ checkStrictly: true }" placeholder="请选择区域" size="large" v-model="queryForm.region" :options="options" clearable></el-cascader>
                </el-form-item>
                <el-form-item prop="dataRange">
                    <el-date-picker v-model="queryForm.dataRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
                </el-form-item>
                <el-form-item class="floatRight">
                    <el-dropdown split-button type="primary" @click="queryData" @command="handleCommand">
                        查询
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="reset">重置查询</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row v-if="userInfo.userType == 1" class="marginBottom-8" id="addBtnRow">
            <el-button type="primary" @click="mediaAdd">新增点位</el-button>
        </el-row>
        <el-table :max-height="tableHeight" v-loading="listLoading" element-loading-text="加载中" :data="list" border fit highlight-current-row>
            <el-table-column show-overflow-tooltip label="点位编号" align="center">
                <template slot-scope="scope">
                    {{ scope.row.pointId }}
                </template>
            </el-table-column>
            <el-table-column label="点位名称" align="center">
                <template slot-scope="scope">
                    {{ scope.row.name || "--" }}
                </template>
            </el-table-column>
            <el-table-column label="创建人" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="queryUserInfo(scope.row.userCode)">{{ scope.row.userCode || "--" }}</el-button>
                </template>
            </el-table-column>
            <el-table-column label="点位区域" align="center">
                <template slot-scope="scope">
                    {{ scope.row.province + scope.row.city + scope.row.district }}
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="具体地址" align="center">
                <template slot-scope="scope">
                    {{ scope.row.address }}
                </template>
            </el-table-column>
            <el-table-column label="上传时间" align="center">
                <template slot-scope="scope">
                    <span>{{ moment(scope.row.addTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-popconfirm title="确定删除吗？" @confirm="del(scope.row.pointId)">
                        <el-button slot="reference" type="text" size="small">删除</el-button>
                    </el-popconfirm>
                    <el-button class="marginLeft-8" type="text" size="small" @click="edit(scope.row.pointId)">修改</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination id="pagination" class="marginTop-8 floatRight" layout="total, sizes, prev, pager, next" background :current-page="queryForm.pageNum + 1" :page-size="queryForm.pageSize" :total="queryForm.totalCount" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
        <media-add :visible="mediaAddVisible" :op="op" @onDialogClose="medieaAddClose" />
        <UserInfo :visible="drawerVisible" :userCode="userCode" @closeDrawer="closeDrawer" />
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getDatapointPageList, dataPointDel } from "@/api/statisticalReport";
import { queryRegionList } from "@/api/user";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
import { solution, computeTableHeight } from "@/utils";
import state from "@/utils/state";
import moment from "moment";
import MediaAdd from "./components/MediaAdd";
import UserInfo from "@/components/UserInfo";

export default {
    components: { MediaAdd, UserInfo },
    data() {
        return {
            tableHeight: 0,
            drawerVisible: false,
            userCode: null,
            mediaAddVisible: false,
            options: regionData,
            listLoading: true,
            list: [],
            mediaList: [],
            moment,
            diaLogShow: false,
            op: "add",
            queryForm: {
                querySelectedType: "name",
                querySelectedValue: "",
                region: [],
                dataRange: "",
                pageNum: 0,
                pageSize: 10,
                totalCount: 0,
            },
        };
    },
    computed: {
        state() {
            return state.state;
        },
        ...mapGetters(["userInfo"]),
    },
    created() {
        this.fetchData();
        if (this.userInfo.userType == 1) {
            this.getRegion();
        }
    },
    mounted() {
        const _this = this;
        this.$nextTick(() => {
            _this.tableHeight = computeTableHeight();
        });
        window.addEventListener("resize", () => {
            _this.tableHeight = computeTableHeight();
        });
    },
    methods: {
        getRegion() {
            let params = {
                userCode: this.userInfo.userCode,
            };
            const _this = this;
            queryRegionList(params)
                .then((res) => {
                    let dataList = res.data.dataList;
                    const keys = ["province", "city", "district"];
                    // dataList 是一个扁平的数组，是需要转换成城市三级级联数组才能给级联器使用
                    let list = solution(dataList, keys);
                    list.map((pItem) => {
                        pItem.label = pItem.value;
                        pItem.value = TextToCode[pItem.label].code;
                        pItem.children.map((cItem) => {
                            cItem.label = cItem.value;
                            cItem.value = TextToCode[pItem.label][cItem.label].code;
                            cItem.children.map((dItem) => {
                                dItem.label = dItem.value;
                                dItem.value = TextToCode[pItem.label][cItem.label][dItem.label].code;
                            });
                        });
                    });
                    _this.options = list;
                })
                .catch((err) => {
                    _this.canSubmit = false;
                    if (_this.pageLoading) {
                        _this.pageLoading.close();
                    }
                });
        },
        medieaAddClose(refeshFlag) {
            this.mediaAddVisible = false;
            this.op = "add";
            if (refeshFlag == true) {
                this.$refs.queryForm.resetFields();
                this.fetchData();
            }
        },
        closeDrawer() {
            this.drawerVisible = false;
            this.userCode = null;
        },
        queryUserInfo(userCode) {
            this.userCode = userCode;
            this.drawerVisible = true;
        },
        mediaAdd() {
            this.mediaAddVisible = true;
        },
        del(pointId) {
            let params = {
                pointId: pointId,
            };
            const _this = this;
            dataPointDel(params)
                .then((res) => {
                    _this.fetchData();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        handleClick(e) {
            this.fetchData();
        },
        editUser(userCode) {
            this.op = userCode;
            this.addAccount();
        },
        handleSizeChange(val) {
            this.queryForm.pageSize = val;
            this.fetchData();
        },
        handleCurrentChange(val) {
            this.queryForm.pageNum = val - 1;
            this.fetchData();
        },
        edit(pointId) {
            this.op = pointId;
            this.mediaAddVisible = true;
        },
        addAccount() {
            this.diaLogShow = true;
        },
        handleCommand(type) {
            if (type == "reset") {
                this.$refs.queryForm.resetFields();
                this.fetchData();
            }
        },
        queryData() {
            this.queryForm.pageNum = 0;
            this.fetchData();
        },
        fetchData() {
            this.listLoading = true;
            const queryForm = this.queryForm;
            let params = {
                pageNum: queryForm.pageNum,
                pageSize: queryForm.pageSize,
            };
            if (this.userInfo.userType == 1) {
                params.userCode = this.userInfo.userCode;
            }
            if (queryForm.querySelectedValue && queryForm.querySelectedValue != "") {
                params[queryForm.querySelectedType] = queryForm.querySelectedValue;
            }
            if (queryForm.dataRange && queryForm.dataRange != "") {
                params.maxAddTime = moment(queryForm.dataRange[1]).format("YYYY-MM-DD") + " 23:59:59";
                params.minAddTime = moment(queryForm.dataRange[0]).format("YYYY-MM-DD") + " 00:00:00";
            }
            if (queryForm.region.length == 1) {
                params.province = CodeToText[queryForm.region[0]];
            } else if (queryForm.region.length == 2) {
                params.province = CodeToText[queryForm.region[0]];
                params.city = CodeToText[queryForm.region[1]];
            } else if (queryForm.region.length == 3) {
                params.province = CodeToText[queryForm.region[0]];
                params.city = CodeToText[queryForm.region[1]];
                params.district = CodeToText[queryForm.region[2]];
            }
            const _this = this;
            getDatapointPageList(params)
                .then((response) => {
                    _this.list = response.data.dataList;
                    _this.queryForm.totalCount = response.data.totalCount;
                    _this.listLoading = false;
                })
                .catch((err) => {
                    _this.listLoading = false;
                });
        },
    },
};
</script>
