<template>
    <el-dialog append-to-body title="选择地址" width="70%" :close-on-click-modal="false" :visible="locationSelectedVisible" @close="closeDialog">
        <div id="amap-container">
            <el-row class="margin-top-10 address">
                所选地址是： {{ formattedAddress || "" }}
                <el-button size="small" type="primary" :disabled="!formattedAddress" @click="handelSave">使用该地址</el-button>
            </el-row>
            <div class="mapBox">
                <div id="custom-amap"></div>
                <el-input id="search-input" v-model="searchValue" class="input-with" placeholder="请输入地址" clearable @clear="handelclearInput" @keyup.native.enter="handelSearch">
                    <el-button slot="append" size="small" type="primary" icon="el-icon-search" @click="handelSearch">搜索</el-button>
                </el-input>
                <div id="searchResultPanel" class="searchResultPanel" />
            </div>
        </div>
    </el-dialog>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
    props: ["locationSelectedVisible"],
    data() {
        return {
            defaultCity: "全国",
            // 地图对象
            map: null,
            // 定位默认地址 | 搜索后选择的地址
            formattedAddress: null,
            // 地址对应的经纬度信息
            position: {},
            // 检索关键字
            searchValue: "",
            // 检索函数对象
            placeSearch: null,
            // 检索结果数据数据
            searchInfoList: [],
            // 地图标记
            marker: "",
            // 地址解析（正向）
            geocoder: "",
            // 地址名称
            name: "",
        };
    },
    watch: {
        locationSelectedVisible(data) {
            const _this = this;
            if (data) {
                AMapLoader.load({
                    key: "a68cfa9e236bde4322550467cef9a13b", // 申请好的Web端开发者Key，首次调用 load 时必填
                    version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                    plugins: ["AMap.Geocoder", "AMap.Geolocation", "AMap.AutoComplete", "AMap.PlaceSearch"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                    AMapUI: {
                        // 是否加载 AMapUI，缺省不加载
                        version: "1.1", // AMapUI 缺省 1.1
                        plugins: [], // 需要加载的 AMapUI ui插件
                    },
                })
                    .then((AMap) => {
                        _this.AMap = AMap;
                        _this.$nextTick(() => _this.initMap(AMap));
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            }
        },
    },
    methods: {
        initMap(AMap) {
            this.map = new AMap.Map("custom-amap");
            // 添加maker
            this.setMaker();
            // 添加鼠标点选地图选择地址
            this.addAmapGeocoder();
            // 添加检索提示
            this.addAMapAutocompletePlaceSearch();
        },
        // 添加maker
        setMaker() {
            this.marker = new AMap.Marker();
            this.map.add(this.marker);
            // 添加解析地理位置插件
            // 异步加载插件
            this.geocoder = new AMap.Geocoder({
                city: this.defaultCity, // 默认：“全国”
                radius: 1000, // 范围，默认：500
            });
        },
        // 添加鼠标点选地图选择地址
        addAmapGeocoder() {
            // 添加maker
            // this.setMaker();
            // 地图添加点击事件
            this.map.on("click", (e) => {
                const lnglat = [e.lnglat.lng, e.lnglat.lat];
                // this.marker.setPosition(lnglat);
                this.geocoder.getAddress(lnglat, (status, result) => {
                    if (status === "complete" && result.regeocode) {
                        this.placeSearch.searchNearBy("", [lnglat[0], lnglat[1]], 200, function (status, result) {});
                    } else {
                        alert(JSON.stringify(result));
                    }
                });
            });
        },
        // 添加检索提示检索
        addAMapAutocompletePlaceSearch() {
            // 自动提示
            const auto = new AMap.AutoComplete({
                city: this.defaultCity,
                input: "search-input",
            });
            // 添加检索监听
            this.AMap.Event.addListener(auto, "select", this.onSelectAutocomplete);
            // 检索服务
            // 构造地点查询类
            this.placeSearch = new AMap.PlaceSearch({
                type: "餐饮服务|购物服务|生活服务|体育休闲服务|医疗保健服务|住宿服务|风景名胜|商务住宅|政府机构及社会团体|科教文化服务|交通设施服务|金融保险服务|公司企业|道路附属设施|地名地址信息|公共设施", // 兴趣点类别
                pageSize: 50, // 单页显示结果条数
                pageIndex: 1, // 页码
                city: this.defaultCity, // 兴趣点城市
                citylimit: false, // 是否强制限制在设置的城市内搜索
                map: this.map, // 展现结果的地图实例
                panel: "searchResultPanel", // 结果列表将在此容器中进行展示。
                autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
            });
            // 添加检索监听
            this.AMap.Event.addListener(this.placeSearch, "listElementClick", this.onSelectSearch);
        },
        // 按钮触发检索
        handelSearch() {
            this.placeSearch.search(this.searchValue, (status, info) => {
                this.searchInfoList = info.poiList.pois;
            });
        },
        // 选择自动提示数据事件回调
        onSelectAutocomplete(e) {
            this.searchValue = e.poi.name;
            this.handelSearch();
        },
        // 选择检索数据结果事件回调
        onSelectSearch(e) {
            const res = e.data;
            this.formattedAddress = res.pname + res.cityname + res.adname + res.name;
            this.province = res.pname;
            this.city = res.cityname;
            this.district = res.adname;
            this.name = res.name;
            this.position = res.location;
            this.adcode = res.adcode;
        },
        // 清除input里的值，清除搜索结果，再次初始化map
        handelclearInput() {
            document.querySelector("#searchResultPanel").innerHTML = "";
            this.map.clearMap();
        },
        // 保存当前选择的地址,分发事件
        handelSave() {
            this.searchValue = this.formattedAddress;
            const { lat, lng } = this.position;
            const data = {
                adcode: this.adcode,
                name: this.name,
                province: this.province,
                city: this.city,
                district: this.district,
                // 地址名称
                address: this.formattedAddress,
                // 纬度lat
                lat,
                // 经度lng
                lng,
            };
            this.closeDialog(data);
        },
        closeDialog(data) {
            this.$emit("closeMapSelected", data);
            if (this.map) {
                this.map.clearMap();
                this.map.destroy();
                this.map = null;
            }
            this.formattedAddress = null;
            this.name = "";
            this.province = "";
            this.city = "";
            this.district = "";
            this.address = "";
            this.lat = "";
            this.lng = "";
            document.querySelector("#searchResultPanel").innerHTML = "";
            this.searchValue = "";
        },
    },
};
</script>
<style lang="less" scoped>
#amap-container {
    .el-input__clear {
        line-height: 34px;
        // top: 20px;
    }
    .mapBox {
        position: relative;
        #custom-amap {
            height: 60vh;
            width: 100%;
            margin-top: 10px;
            border: 1px solid #ccc;
        }
        .input-with {
            position: absolute;
            top: 8px;
            left: 8px;
            z-index: 1;
            width: 580px;
        }
        .searchResultPanel {
            position: absolute;
            right: 8px;
            top: 8px;
            width: 300px;
            height: 100%;
            z-index: 1;
            overflow-x: auto;
        }
    }
    .address {
        color: #373737;
    }
}
.amap-sug-result {
    z-index: 99999;
}
</style>
